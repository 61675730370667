/**
 * Created by logistica on 27/12/2016.
 */

$(function(){
    App.initHelper('slick');
});





